/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/assets/stylesheets/normalize.css';
import ReactGA from 'react-ga';
import Url from 'url';

export function onInitialClientRender() {
  if (window.history.length == 2 && window.location.pathname != '/') {
    history.pushState({}, document.title, window.location.pathname);
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  if (typeof Array.prototype.flatMap == 'undefined') {
    Array.prototype.flatMap = function(lambda) {
      return Array.prototype.concat.apply([], this.map(lambda));
    };
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window == 'undefined') return true;
  window.previousUrl = Url.parse(
    prevLocation ? prevLocation['href'] : document.referrer
  );
};

export const onRouteUpdate = ({ location, _prevLocation }) => {
  if (process.env.GATSBY_ENV == 'production') {
    ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID);
    ReactGA.ga('require', process.env.GATSBY_GOOGLE_OPTIMIZE_ID);
    ReactGA.ga('set', 'dimension2', process.env.GATSBY_GIT_BRANCH);
    ReactGA.pageview(location.pathname + location.search + location.hash);
  }
};
